.container {
  height: 100%;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.header {
  background: #fff;

  .icon {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }
}

.content {
  background: #fff;
  margin: 20px 18px;
  padding: 2px 16px;
}
